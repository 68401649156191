import { Component, computed, input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { tablerLoader2 } from '@ng-icons/tabler-icons';
import { fadeInOutAnimation } from '../../animations/fade-animations';

@Component({
  selector: 's-loading',
  standalone: true,
  imports: [
    // third-party
    NgIconComponent,
  ],
  template: `
    <ng-icon
      class="animate-spin text-primary"
      name="tablerLoader2"
      [size]="parsedSize()"
      [strokeWidth]="parsedStrokeWidth()"
    ></ng-icon>
  `,
  host: {
    class: 'flex items-center justify-center w-full h-full bg-background',
  },
  viewProviders: [
    provideIcons({
      tablerLoader2,
    }),
  ],
})
export class LoadingComponent {
  //#region Data Variables
  size = input<number | string>(32);
  strokeWidth = input<number | string>(2);

  protected parsedSize = computed(() => {
    const size = this.size();

    if (typeof size === 'string') {
      return size;
    }

    return size.toString();
  });

  protected parsedStrokeWidth = computed(() => {
    const strokeWidth = this.strokeWidth();

    if (typeof strokeWidth === 'string') {
      return strokeWidth;
    }

    return strokeWidth.toString();
  });
  //#endregion
}
